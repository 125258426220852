.a {
  text-decoration: none;
}
.flex {
  display: flex;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-jc-c {
  justify-content: center;
}

.flex-ai-c {
  align-items: center;
}

.footer {
  background: #383838;
  color: white;
  padding: 2.5rem;
  padding-bottom: 0;
  text-align: center;
}

@media (min-width: 64em) {
  .footer {
    padding: 3.125rem;
    padding-bottom: 0;
  }
}

@media (min-width: 64em) {
  .footer .container {
    display: grid;
    grid-template-areas:
      "logo col1 col2 cta"
      "social col1 col2 copyright";
    justify-items: start;
    gap: 1rem;
  }
}

@media (max-width: 63.9375em) {
  .footer .footer-logo {
    margin-bottom: 1.875rem;
  }
}

@media (min-width: 64em) {
  .footer .footer-logo {
    grid-area: logo;
  }
}

@media (max-width: 63.9375em) {
  .footer .footer-social-media {
    margin-bottom: 1.875rem;
  }
}

@media (min-width: 64em) {
  .footer .footer-social-media {
    grid-area: social;
    align-self: end;
  }
}

.footer .footer-social-media a:not(:last-child) {
  margin-right: 1rem;
}

.footer .footer-social-media a:hover svg path {
  fill: #aa2c44;
  transition: all 0.2s ease-in-out;
}

.footer .footer-links {
  display: flex;
  flex-direction: column;
}

@media (min-width: 64em) {
  .footer .footer-links {
    align-items: flex-start;
  }
}

.footer .footer-links a {
  color: #fff;
  font-size: 0.9375rem;
  line-height: 2.25;
  transition: color 0.2s ease-in-out;
}

.footer .footer-links a:hover {
  color: #aa2c44;
}

@media (min-width: 64em) {
  .footer .col1 {
    grid-area: col1;
  }
}

@media (max-width: 63.9375em) {
  .footer .col2 {
    margin-bottom: 1.875rem;
  }
}

@media (min-width: 64em) {
  .footer .col2 {
    grid-area: col2;
  }
}

@media (max-width: 63.9375em) {
  .footer .footer-cta {
    margin-bottom: 1.875rem;
  }
}

@media (min-width: 64em) {
  .footer .footer-cta {
    grid-area: cta;
    justify-self: end;
  }
}

.footer .footer-copyright p {
  color: #9698a6;
  font-size: 0.875rem;
}

@media (min-width: 64em) {
  .footer .footer-copyright {
    grid-area: copyright;
    justify-self: end;
    align-self: end;
  }
}

.attribution {
  color: #fff;
  font-size: 0.8125rem;
  text-align: center;
  padding-top: 1.875rem;
  padding-bottom: 1.5625rem;
}

.attribution .outer-link {
  color: #fff;
  margin-left: 0.0625rem;
  transition: all 0.2s ease-in-out;
}

.attribution .outer-link:hover {
  color: #57db7a;
  cursor: pointer;
}

.attribution .social-media {
  list-style: none;
  margin-left: 0.3125rem;
  display: inline-flex;
  align-items: flex-end;
  gap: 0.75rem;
}

.attribution .social-media img {
  width: 22px;
  height: 22px;
  transition: transform 0.2s ease-in-out;
}

.attribution .social-media img:hover {
  transform: scale(1.08);
}

.attribution .social-media img.light {
  filter: invert(1);
}
