* {
    box-sizing: border-box;
}

:root {
    --lite: #c0a8da;
    --primary:  rgb(165, 21, 21);
}

.bar {
    display: none;
    height: 2px;
    width: 100px;
    background: var(--primary);
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin: 20px auto 20px 0;
}
.nav_link{
    text-decoration: none;
    color: rgb(82, 124, 235);
}

.tickicon{
    color: rgb(82, 124, 235);
    font-size: 20px;
}
.nav_link:hover{
    color: white;
}
.bar:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: #fff;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}

@keyframes MOVE-BG {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(85px);
        transform: translateX(85px);
    }
}





.title_header {
    width: 60%;
    text-align: Center;
    margin: auto;
    color: var(--white);
}

.title_header h1 {
    font-size: 2em;
}

.title_header h5 {
    font-weight: 200;
}

.title_header .bar {
    margin: auto;
}

@media (max-width:820px) {
    .title_header {
        width: 90%;
    }

    .title_header h1 {
        font-size: 1.5em;
    }
}
.card_con_course{

}
.trustcontationer{
    padding: 16vh;
}
.testseriescon{
    border: 0px;
    background-color: transparent;
    
}

.trunstcont{
    /* background-color: aliceblue; */
    /* padding: 80px 30px; */
    /* background-repeat: no-repeat; */
    /* background-position: bottom left; */
}
/*SECTION1*/
.section1 {
    width: 100%;
    background-color: #fff;
    padding: 60px 0;
}

.section1 section {
    display: flex;
    align-items: center;
    justify-content: Center;
}

.section1 .c1{
    padding: 2rem;
    position: relative;
    border-radius: 0px;
    margin: 10px;
    /* background-image: url("/public/v2/cardback.png");
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: bottom left; */
    text-align: center;
    box-shadow: 0px 4px 14px -4px rgba(1, 1, 1, 0.5);

    transition: 0.5s;
    display: flex;
}
.section1 .c1:hover{
    background-size: 310px;

}
.section1 .c2:hover{
    background-size: 310px;

}
.section1 .c2{
    padding: 2rem;
    position: relative;
    border-radius: 10px;
    margin: 10px;
    box-shadow: 0px 4px 14px -4px rgba(1, 1, 1, 0.5);


    /* background-image: url("/public/v2/cardback1.png");
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: bottom right; */
    text-align: center;
    transition: 0.5s;
    display: flex;
}


.section1 .card:hover {
    box-shadow: 0px 4px 14px -4px rgba(1, 1, 1, 0.5);
}

.section1 .card .tag {
    position: absolute;
    background-color: var(--lite);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    position: absolute;
    top: 2%;
    right: 2%;
}

.section1 .card img {
    width: 600px;
    display: block;
    margin: auto;
    margin-bottom: 30px;
}

.section1 .card h1 {
    font-size: 1.5em;
}

.section1 .card p {
    color: rgba(1, 1, 1, 0.7);
    line-height: 1.8em;
}

.courseimgcard{
    width: 300px;

}
.courseenquiry{
    text-decoration: none;
    color: white;
    padding: 2px 10px;
    border: 2px solid white;
    border-radius: 5px;
}
.courseenquiry:hover{
    background-color: rgb(82, 124, 235);
    color: white;
    border: 1px solid white
}
.courselearnmore{
    background: white;
    color: rgb(82, 124, 235);
    border: 0px solid;
    border: 1px solid rgb(82, 124, 235)
}
.courselearnmore:hover{
    background: rgb(82, 124, 235);
    color: white;
}
.coursecardtext{
    padding: 20px;
    font-size: 18px;
    text-align: justify;
}
.cardbottom{
    margin: 5px;
}
#trustpoints{
    padding: 5vh 10vh;
     font-weight: 300;
    word-spacing: 0.5rem;
    letter-spacing: 0.01rem;
    text-align: center;
}
#trustbutton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
@media (max-width:820px) {
    .section1 section {
        width: 100%;
        padding: 2rem 1rem;
        display: block;
    }

    .section1 .card {
        padding: 1rem;
        margin: 20px 0;
    }
}

#whyimpulserowcon{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 750px) { 
    .section1 .c1{
        padding: 0.2rem;
        position: relative;
        border-radius: 0px;
        margin: 0px;
        /* background-image: url("/public/v2/cardback.png");
        background-size: 300px;
        background-repeat: no-repeat;
        background-position: bottom left; */
        text-align: center;
        box-shadow: 0px 1px 2px -1px rgba(1, 1, 1, 0.5);
        transition: 0.5s;
        display: block;
    }

    
.section1 .c2{
    padding: .2rem;
    position: relative;
    border-radius: 10px;
    margin: 0px;
    box-shadow: 0px 1px 2px -1px rgba(1, 1, 1, 0.5);

    /* background-image: url("/public/v2/cardback1.png");
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: bottom right; */
    text-align: center;
    transition: 0.5s;
    display: flex;
    flex-direction: column-reverse;
}


    .section1 {
        width: 100%;
        background-color: #fff;
        padding: 30px 0;
        padding-bottom: 0px;
    }
    .trustcontationer{
        padding: 2vh;
    }
    
.trunstcont{
    padding: 0px 0px;
    /* background-repeat: no-repeat; */
    /* background-position: bottom left; */
}

#trustpoints{
    padding: 2vh 0vh;
    font-size: 19px;
    text-align: left;
}
#whyimpulserowcon{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#trustbutton{
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
}
}